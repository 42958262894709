import React, { useState, useEffect, useCallback, useRef } from 'react';
import { collection, query, where, orderBy, limit, startAfter, getDocs, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../components/Firebase';
import debounce from 'lodash/debounce';
import { toast } from 'react-hot-toast';
import InfiniteScroll from 'react-infinite-scroll-component';

const PAGE_SIZE = 10;

function SideBarChat({ targetClinicId, onSelectPatient, selectedPatient }) {
  const [chats, setChats] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const lastDocRef = useRef(null);
  const unsubscribeRef = useRef(null);
  const lastMessageTimestampsRef = useRef(new Map());

  // Load more chats for infinite scroll
  const loadMoreChats = async () => {
    if (!hasMore || loading || !lastDocRef.current || !targetClinicId) return;

    setLoading(true);
    try {
      const q = query(
        collection(db, 'Chats'),
        where('ownedClinicId', '==', targetClinicId),
        orderBy('lastMessageTimestamp', 'desc'),
        startAfter(lastDocRef.current),
        limit(PAGE_SIZE)
      );

      const snapshot = await getDocs(q);
      const newChats = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (newChats.length > 0) {
        setChats(prev => {
          // Sort all chats by lastMessageTimestamp
          const allChats = [...prev, ...newChats];
          return allChats.sort((a, b) => 
            new Date(b.lastMessageTimestamp) - new Date(a.lastMessageTimestamp)
          );
        });
        newChats.forEach(chat => {
          lastMessageTimestampsRef.current.set(chat.id, chat.lastMessageTimestamp);
        });
        lastDocRef.current = snapshot.docs[snapshot.docs.length - 1];
      }
      
      setHasMore(snapshot.docs.length === PAGE_SIZE);
    } catch (error) {
      console.error('Error loading more chats:', error);
      toast.error('載入更多聊天記錄失敗');
    } finally {
      setLoading(false);
    }
  };

  // Real-time updates subscription
  const subscribeToRecentChats = useCallback(() => {
    if (!targetClinicId) return;

    const q = query(
      collection(db, 'Chats'),
      where('ownedClinicId', '==', targetClinicId),
      orderBy('lastMessageTimestamp', 'desc'),
      limit(20)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach(change => {
        const chatData = { id: change.doc.id, ...change.doc.data() };
        const lastKnownTimestamp = lastMessageTimestampsRef.current.get(chatData.id);
        const currentTimestamp = chatData.lastMessageTimestamp;

        // If it's a new message
        if ((change.type === 'modified' || change.type === 'added') && 
            lastKnownTimestamp && 
            currentTimestamp !== lastKnownTimestamp && 
            (!selectedPatient || selectedPatient.id !== chatData.id)) {

          // Show notification
          toast((t) => (
            <div className="flex items-center justify-between bg-white shadow-lg rounded-lg p-4">
              <div>
                <p className="font-bold">{chatData.patientName}</p>
                <p className="text-sm text-gray-600">{chatData.lastMessage}</p>
              </div>
              <button
                onClick={() => {
                  onSelectPatient(chatData);
                  toast.dismiss(t.id);
                }}
                className="ml-4 px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                查看
              </button>
            </div>
          ), {
            duration: 4000,
            position: 'top-right',
          });
        }

        // Update the timestamp reference
        lastMessageTimestampsRef.current.set(chatData.id, currentTimestamp);

        // Update chats list with sorting
        setChats(prev => {
          const existingIndex = prev.findIndex(chat => chat.id === chatData.id);
          let updatedChats = [...prev];
          
          if (existingIndex === -1) {
            updatedChats = [chatData, ...updatedChats];
          } else {
            updatedChats[existingIndex] = chatData;
          }

          // Sort by lastMessageTimestamp
          return updatedChats.sort((a, b) => 
            new Date(b.lastMessageTimestamp) - new Date(a.lastMessageTimestamp)
          );
        });
      });
    }, (error) => {
      console.error('Error in chat subscription:', error);
      toast.error('聊天更新發生錯誤');
    });

    return unsubscribe;
  }, [targetClinicId, selectedPatient, onSelectPatient]);

  // Initial load and subscription setup
  useEffect(() => {
    if (!targetClinicId) return;

    const initialize = async () => {
      setLoading(true);
      try {
        const q = query(
          collection(db, 'Chats'),
          where('ownedClinicId', '==', targetClinicId),
          orderBy('lastMessageTimestamp', 'desc'),
          limit(PAGE_SIZE)
        );

        const snapshot = await getDocs(q);
        const initialChats = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setChats(initialChats);
        initialChats.forEach(chat => {
          lastMessageTimestampsRef.current.set(chat.id, chat.lastMessageTimestamp);
        });
        lastDocRef.current = snapshot.docs[snapshot.docs.length - 1];
        setHasMore(snapshot.docs.length === PAGE_SIZE);
      } catch (error) {
        console.error('Error loading initial chats:', error);
        toast.error('載入聊天記錄失敗');
      } finally {
        setLoading(false);
      }
    };

    initialize();
    const unsubscribe = subscribeToRecentChats();
    unsubscribeRef.current = unsubscribe;

    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
      lastMessageTimestampsRef.current.clear();
    };
  }, [targetClinicId, subscribeToRecentChats]);

  // Handle chat selection and mark as read
  const handleChatSelect = async (chat) => {
    if (chat.newMessageFromPatient) {
      try {
        // Only update the selected chat's newMessageFromPatient status
        const chatRef = doc(db, 'Chats', chat.id);
        await updateDoc(chatRef, {
          newMessageFromPatient: false
        });
      } catch (error) {
        console.error('Error updating chat status:', error);
      }
    }
    onSelectPatient(chat);
  };

  // Filter chats based on search
  const filteredChats = chats.filter(chat =>
    (chat.patientName || '').toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Count new messages
  const newMessagesCount = chats.filter(chat => chat.newMessageFromPatient).length;

  return (
    <div className="space-y-6">
      <h3 className="text-sm font-semibold flex items-center">
        訊息
        {newMessagesCount > 0 && (
          <span className="text-white text-xs px-2 py-1 ml-2 bg-subMain rounded-full animate-pulse">
            {newMessagesCount}
          </span>
        )}
      </h3>

      <div className="grid grid-cols-12 gap-2">
        <input
          type="text"
          placeholder="搜尋病人姓名"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="col-span-12 bg-transparent border border-border rounded-lg px-2 py-3 text-xs"
        />
      </div>

      <div className="overflow-y-scroll z-0 relative xl:max-h-[780px] max-h-[200px]" id="chatScroller">
        <InfiniteScroll
          dataLength={filteredChats.length}
          next={loadMoreChats}
          hasMore={hasMore}
          loader={<div className="text-center py-4">載入中...</div>}
          scrollableTarget="chatScroller"
        >
          {filteredChats.map((chat) => (
            <div
              key={chat.id}
              onClick={() => handleChatSelect(chat)}
              className={`grid cursor-pointer grid-cols-12 gap-2 px-2 py-4 hover:bg-text transitions hover:rounded border-b border-border
                ${selectedPatient?.id === chat.id ? 'bg-text' : ''}
                ${chat.newMessageFromPatient ? 'bg-yellow-100' : ''}
              `}
            >
              <div className="col-span-2 relative">
                <div className="w-10 h-10 rounded-full bg-cyan-400 flex items-center justify-center border border-dashed border-subMain">
                  <span className="text-2xl font-bold text-white">
                    {chat.patientName.charAt(0)}
                  </span>
                </div>
                {chat.newMessageFromPatient && (
                  <span className="w-[10px] h-[10px] border border-white bg-red-500 rounded-full absolute top-0 right-0 animate-pulse"/>
                )}
              </div>

              <div className="col-span-5 pl-2 space-y-1">
                <h2 className="font-medium text-sm">{chat.patientName}</h2>
                <p className={`text-xs truncate ${chat.newMessageFromPatient ? 'text-black font-semibold' : 'text-textGray'}`}>
                  {chat.lastMessage}
                </p>
              </div>

              <div className="col-span-5">
                <p className="text-textGray text-[13px]">
                  {new Date(chat.lastMessageTimestamp).toLocaleString()}
                </p>
                {chat.newMessageFromPatient && (
                  <div className="flex items-center gap-1">
                    <span className="w-2 h-2 bg-red-500 rounded-full animate-pulse"/>
                    <span className="text-xs text-red-500 font-bold">新訊息</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default SideBarChat;