import React, { useState, useEffect, useRef } from 'react';
import { collection, query, where, orderBy, limit, onSnapshot, setDoc, updateDoc, deleteDoc, doc, getDoc, getDocs } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import SideBarChat from './SideBarChat';
import Conversation from './Coversation';
import { db, storage } from '../../components/Firebase';
import Layout from '../../Layout';
import { useParams } from 'react-router-dom';
import { getTaiwanTimeString } from '../../components/TaiwanTimeStamp';
import toast from 'react-hot-toast';
import useUserStore from '../../stores/useUserStore';

function Chat() {
  const { patientId } = useParams();
  const { user, targetClinicId, targetClinicName, loading } = useUserStore();
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);
  const [patientName, setPatientName] = useState('');
  const [patientUserId, setPatientUserId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [newMessageNotification, setNewMessageNotification] = useState(null);
  const [chats, setChats] = useState([]);

  useEffect(() => {
    if (loading || !user || !targetClinicId) return;

    const fetchOrCreateChat = async () => {
      setIsLoading(true);
      try {
        let chatId, chat, patientDoc;

        if (patientId) {
          patientDoc = await getDoc(doc(db, 'Patients', patientId));
          if (patientDoc.exists() && patientDoc.data().userId) {
            setPatientUserId(patientDoc.data().userId);
            chatId = `${targetClinicId}_${patientDoc.data().userId}`;
          } else {
            console.log('Patient has no userId', patientId);
            toast.error('病患尚未註冊App，請指引病患利用App預約報到後即可使用線上通訊');
            setIsLoading(false);
            return;
          }
        } else {
          setIsLoading(false);
          return; // Don't create a chat if there's no patientId
        }

        const chatRef = doc(db, 'Chats', chatId);
        const chatDoc = await getDoc(chatRef);

        if (!chatDoc.exists()) {
          const newChatData = {
            ownedClinicId: targetClinicId,
            clinicName: targetClinicName,
            patientUserId: patientDoc.data().userId,
            patientName: patientDoc.data().userName,
            createdAt: getTaiwanTimeString(),
            lastMessageTimestamp: getTaiwanTimeString(),
            newMessageFromClinic: false,
            newMessageFromPatient: false
          };
          await setDoc(chatRef, newChatData);
          chat = { id: chatId, ...newChatData };
        } else {
          chat = { id: chatId, ...chatDoc.data() };
          const names = chat.patientName.split(', ');
          const currentName = patientDoc.data().userName;
          const updatedNames = [currentName, ...names.filter(name => name !== currentName)];
          await updateDoc(chatRef, {
            newMessageFromPatient: false,
            patientName: updatedNames.join(', ')
          });
          chat.patientName = updatedNames.join(', ');
        }

        setSelectedPatient(chat);
        setPatientName(chat.patientName.split(', ')[0]);

      } catch (error) {
        console.error('Error fetching or creating chat:', error);
        toast.error('載入聊天資料失敗');
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrCreateChat();
  }, [patientId, targetClinicId, targetClinicName, user, loading]);

  useEffect(() => {
    if (selectedPatient) {
      setIsLoading(true);
      const q = query(
        collection(db, 'Chats', selectedPatient.id, 'Messages'),
        orderBy('timestamp', 'desc'),
        limit(50)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const newMessages = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })).reverse();
        console.log("Fetched messages:", newMessages);
        setMessages(newMessages);
        setIsLoading(false);

        updateDoc(doc(db, 'Chats', selectedPatient.id), {
          newMessageFromPatient: false
        });
      }, (error) => {
        console.error("Error fetching messages:", error);
        setIsLoading(false);
      });

      return () => unsubscribe();
    }
  }, [selectedPatient]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (targetClinicId) {
      const q = query(
        collection(db, 'Chats'),
        where('ownedClinicId', '==', targetClinicId),
        where('newMessageFromPatient', '==', true)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added" || change.type === "modified") {
            const chat = change.doc.data();
            if (chat.newMessageFromPatient && (!selectedPatient || chat.id !== selectedPatient.id)) {
              setNewMessageNotification({
                patientName: chat.patientName.split(', ')[0],
                message: chat.lastMessage,
                chatId: change.doc.id
              });
              showBrowserNotification(chat.patientName.split(', ')[0], chat.lastMessage);
            }
          }
        });
      });

      return () => unsubscribe();
    }
  }, [targetClinicId, selectedPatient]);

  const uploadFile = async (file) => {
    const storageRef = ref(storage, `chat_files/${selectedPatient.id}/${file.name}`);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  };

  const sendMessage = async (e, fileUrl = null, fileType = 'text') => {
    if (e) e.preventDefault();
    if (fileType === 'text' && newMessage.trim() === '') return;

    const timestamp = getTaiwanTimeString();
    const messageData = {
      senderId: targetClinicId,
      senderName: targetClinicName,
      text: fileType === 'text' ? newMessage : '',
      url: fileUrl,
      type: fileType,
      timestamp: timestamp,
      status: 'sending'
    };

    setMessages(prevMessages => [...prevMessages, { id: 'temp-id', ...messageData }]);
    setNewMessage('');

    try {
      const docRef = await setDoc(doc(collection(db, 'Chats', selectedPatient.id, 'Messages')), messageData);

      setMessages(prevMessages => prevMessages.map(msg =>
        msg.id === 'temp-id' ? { ...msg, id: docRef.id, status: 'sent' } : msg
      ));

      const chatRef = doc(db, 'Chats', selectedPatient.id);
      const chatDoc = await getDoc(chatRef);
      if (chatDoc.exists()) {
        const chatData = chatDoc.data();
        const names = chatData.patientName.split(', ');
        const currentName = names[0];
        const updatedNames = [currentName, ...names.filter(name => name !== currentName)];

        await updateDoc(chatRef, {
          lastMessageTimestamp: timestamp,
          lastMessage: fileType === 'text' ? newMessage : `Sent a ${fileType}`,
          newMessageFromClinic: true,
          patientName: updatedNames.join(', ')
        });
      }

    } catch (error) {
      console.error("Error sending message: ", error);
      setMessages(prevMessages => prevMessages.map(msg =>
        msg.id === 'temp-id' ? { ...msg, status: 'error' } : msg
      ));
      toast.error("傳送失敗，請重新傳送");
    }
  };

  const editMessage = async (messageId, newText) => {
    await updateDoc(doc(db, 'Chats', selectedPatient.id, 'Messages', messageId), {
      text: newText,
      editedAt: getTaiwanTimeString()
    });
  };

  const deleteMessage = async (messageId) => {
    await deleteDoc(doc(db, 'Chats', selectedPatient.id, 'Messages', messageId));
  };

  const handleNotificationClick = (chatId) => {
    const chat = chats.find(c => c.id === chatId);
    if (chat) {
      setSelectedPatient(chat);
      setNewMessageNotification(null);
    }
  };

  const showBrowserNotification = (title, body) => {
    if (Notification.permission === "granted") {
      new Notification(title, { body });
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          new Notification(title, { body });
        }
      });
    }
  };

  if (loading || isLoading) {
    return <Layout><div>載入中...</div></Layout>;
  }

  return (
    <Layout>
      <div className="xl:flex bg-white rounded-xl border-[1px] border-border p-5 gap-4">
        <div className="2xl:w-[20%] xl:w-[30%]">
          <SideBarChat
            targetClinicId={targetClinicId}
            onSelectPatient={setSelectedPatient}
            selectedPatient={selectedPatient}
            chats={chats}
            setChats={setChats}
          />
        </div>
        <div className="2xl:w-[80%] xl:w-[70%] bg-slate-50 sm:p-6 p-2 rounded-lg xl:mt-0 mt-6">
          {selectedPatient ? (
            <Conversation
              messages={messages}
              sendMessage={sendMessage}
              editMessage={editMessage}
              deleteMessage={deleteMessage}
              newMessage={newMessage}
              setNewMessage={setNewMessage}
              messagesEndRef={messagesEndRef}
              clinicName={targetClinicName}
              targetClinicId={targetClinicId}
              selectedPatient={selectedPatient}
              uploadFile={uploadFile}
            />
          ) : (
            <div className="flex items-center justify-center h-full">
              <p>點選左欄開始對話 或到 <a href="/patients" className="underline text-blue-500">診所病患頁面</a> 尋找病患->點選詳情->點選關懷->即時線上通訊 即可與病患聯繫</p>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Chat;