import React from 'react';
import { MenuDatas } from '../components/Datas';
import { Link } from 'react-router-dom';

function Sidebar() {
  // active link
  const currentPath = (path) => {
    const currentPath =
      window.location.pathname.split('/')[1] === path.split('/')[1];
    if (currentPath) {
      return path;
    }
    return null;
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="bg-white xl:shadow-lg py-6 px-4 xl:h-screen w-full border-r border-border flex flex-col justify-between">
      {/* Main Content */}
      <div>
        <Link to="/">
          <img
            src="/images/logo2.jpg"
            alt="logo"
            className="w-3/4 ml-4 object-contain"
          />
          <p className="text-center mt-2 text-2xl font-semibold">醫師工具箱 </p>
          <p className="text-center mt-2 text- font-semibold">Doctor Toolbox </p>
        </Link>
        <div className="flex-colo gap-2 mt-6">
          {MenuDatas.map((item, index) => (
            <Link
              to={item.path}
              key={index}
              className={`
              ${currentPath(item.path) === item.path ? 'bg-text' : ''}
              flex gap-4 transitions group items-center w-full p-4 rounded-lg hover:bg-text`}
            >
              <item.icon
                className={`text-xl text-subMain`}
              />
              <p
                className={`text-sm font-medium group-hover:text-subMain ${currentPath(item.path) === item.path
                    ? 'text-subMain'
                    : 'text-gray-500'
                  }`}
              >
                {item.title}
              </p>
            </Link>
          ))}
        </div>
      </div>

      {/* Footer Info */}
      <div className="pt-2 mt-6 border-t border-border">
        <div className="text-sm text-gray-600 space-y-2">
          <p className="text-xs text-gray-500 mt-4">
            © {currentYear} 醫師工具箱 All rights reserved.
          </p>
          <p className="text-xs text-gray-500 mt-4">
            地址：台中市太平區新福路208號</p>
          <p className="text-xs text-gray-500 mt-4">
            Email：stanmanho@hotmail.com</p>
          <Link
            to="/privacy-policy"
            className="text-blue-600 text-xs hover:text-blue-800 transition-colors block"
          >
            隱私權政策
          </Link>
        </div>

      </div>
    </div>
  );
}

export default Sidebar;