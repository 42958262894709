import React from 'react';
import Layout from '../Layout';

const PrivacyPolicy = () => {
    return (
        <Layout>
            <div className="max-w-4xl mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-8">隱私權政策</h1>

                <div className="space-y-6">
                    <section>
                        <h2 className="text-2xl font-semibold mb-4">個人資料蒐集聲明</h2>
                        <p className="text-gray-700">
                            醫師工具箱（以下簡稱「本公司」）重視您的隱私權，為了提供您更好的服務，我們依據個人資料保護法及相關法令規定，制定本隱私權政策。
                            在您使用本公司的服務時，我們將依照本隱私權政策蒐集、處理及利用您的個人資料。當會員完成本網站之會員註冊手續或開始使用本網站服務時，即表示已閱讀並同意接受本網站服務條款之所有內容。在您決定使用本服務前，請仔細閱讀本政策。
                            本公司有權於任何時間修改或變更本服務條款內容，修改後將公布本網站上，不再個別通知會員，建議會員隨時注意修改或變更。會員於任何修改或變更後繼續使用本網站服務時，視為會員已瞭解並同意接受該等修改或變更。若不同意上述的條款修訂或更新，或不接受本服務條款的任一約定，會員應立即停止使用本網站服務。會員同意使用本網站服務所生權利義務，得以電子文件為作表示方式。
                        </p>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold mb-4">個人資料蒐集項目</h2>
                        <p className="text-gray-700 mb-4">
                            本公司將依據本政策，於法規許可範圍處理個人資料，並以合法、公平方式搜集下列個人資料：
                        </p>
                        <ul className="list-disc pl-6 space-y-2 text-gray-700">
                            <li>基本資料：姓名、身分證字號、出生年月日、聯絡電話、電子郵件等</li>
                            <li>醫療資訊：就診紀錄、診斷資料、治療計畫等</li>
                            <li>系統使用資料：IP位址、瀏覽器類型、操作系統等</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold mb-4">個人資料的利用</h2>
                        <p className="text-gray-700 mb-4">
                            本公司為提供及維護本服務、開發及改善本服務、運用於研究及開發以提供更安全、優化之本服務等目的，所搜集之個人資料（包括使用本服務所搜集的個人資料及自第三方處搜集的所有個人資料），用以向用戶提供更安全和更優質服務，並提供對用戶而言更具切身關聯的服務（包括廣告）。
                            此類運用包含針對分配予用戶的本服務內部識別碼、合作診所的識別碼、廣告識別碼及其他各種識別碼、電子郵件地址及電話號碼的相關管理與使用。
                            本公司可能會自合作診所使用本服務的範圍內，搜集您的資訊，並為本條所載用途，將其與本公司資訊結合使用，例如發布來自合作診所的訊息、投放廣告、衡量廣告效果、創建及提供統計資訊與其他目的。
                            除非取得您的同意或在法律許可範圍內，本公司不會在使用目的範圍外處理個人資料。同時，我們將採取適當措施，確保個人資料不會被用於任何其他目的。
                            縱有上述約定，在自第三方處收受個人資料時，若該等個人資料另有其他利用目的，本公司將會於適用法律允許的範圍內，依據提供目的使用該等個人資料。
                            本公司蒐集您的個人資料主要用於：
                        </p>
                        <ul className="list-disc pl-6 space-y-2 text-gray-700">
                            <li>提供醫療診所管理服務</li>
                            <li>進行客戶服務及支援</li>
                            <li>系統維護與改善</li>
                            <li>依法令規定或主管機關要求</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold mb-4">資料安全與保護</h2>
                        <p className="text-gray-700">
                            本公司採取適當的安全措施來保護您的個人資料，包括但不限於加密傳輸、防火牆設置等技術與管理措施，以防止未經授權的存取、使用或洩漏。
                            本公司所提供之各項功能，均依該功能當時之現況提供使用，對於其效能、速度、完整性、可靠性、安全性、正確性等，皆不負擔任何明示或默示之擔保責任。本公司竭力維護本網站服務之網頁、伺服器、網域等所傳送的電子郵件或其內容不含有電腦病毒等有害物；但無法保證郵件、檔案或資料之傳輸儲存均正確無誤不會斷線和出錯等，因各該郵件、檔案或資料傳送或儲存失敗、遺失或錯誤等所致之損害，本網站不負賠償責任。
                        </p>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold mb-4">個人資料的保存期限</h2>
                        <p className="text-gray-700">
                            本公司將依據相關法令規定或蒐集目的存續期間保存您的個人資料。當保存期限屆滿或蒐集目的已達成時，本公司將依法銷毀或刪除您的個人資料。
                        </p>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold mb-4">您的權利</h2>
                        <p className="text-gray-700 mb-4">
                            依據個人資料保護法，您就您的個人資料享有以下權利：
                        </p>
                        <ul className="list-disc pl-6 space-y-2 text-gray-700">
                            <li>查詢或請求閱覽</li>
                            <li>請求製給複製本</li>
                            <li>請求補充或更正</li>
                            <li>請求停止蒐集、處理或利用</li>
                            <li>請求刪除</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold mb-4">隱私權政策修改</h2>
                        <p className="text-gray-700">
                            本公司保留隨時修改本隱私權政策的權利，修改後的內容將公布於本網站。若您繼續使用本公司的服務，即表示您同意修改後的隱私權政策。
                        </p>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold mb-4">聯絡我們</h2>
                        <p className="text-gray-700">
                            如您對本隱私權政策有任何疑問或建議，歡迎透過以下方式與我們聯絡：<br />
                            Email：stanmanho@hotmail.com<br />
                            地址：台中市太平區新福路208號
                        </p>
                    </section>
                </div>
            </div>
        </Layout>
    );
};

export default PrivacyPolicy;