import React, { useState, useEffect } from 'react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { FiEdit } from 'react-icons/fi';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import Modal from '../../components/Modals/Modal';
import { db } from '../../components/Firebase';

const MessageTemplateModal = ({ isOpen, onClose, clinicId, onSelectTemplate }) => {
    const [templates, setTemplates] = useState([]);
    const [newTemplate, setNewTemplate] = useState('');
    const [editingTemplate, setEditingTemplate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);



    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const clinicDoc = await getDoc(doc(db, 'OwnedClinic', clinicId));
                if (clinicDoc.exists()) {
                    const data = clinicDoc.data();
                    setTemplates(data.messageTemplates || []);
                }
            } catch (error) {
                console.error('Error fetching templates:', error);
                toast.error('無法載入訊息範本');
            }
        };

        fetchTemplates();
    }, [clinicId]);

    const updateTemplatesInFirebase = async (newTemplates) => {
        try {
            const clinicRef = doc(db, 'OwnedClinic', clinicId);
            await updateDoc(clinicRef, {
                messageTemplates: newTemplates
            });
        } catch (error) {
            console.error('Error updating templates:', error);
            throw error;
        }
    };

    const handleAddTemplate = async () => {
        if (!newTemplate.trim()) return;

        setIsLoading(true);
        try {
            const newTemplates = [...templates, newTemplate.trim()];
            await updateTemplatesInFirebase(newTemplates);
            setTemplates(newTemplates);
            setNewTemplate('');
            toast.success('已新增訊息範本');
        } catch (error) {
            toast.error('新增範本失敗');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteTemplate = async (index) => {
        try {
            const newTemplates = templates.filter((_, i) => i !== index);
            await updateTemplatesInFirebase(newTemplates);
            setTemplates(newTemplates);
            toast.success('已刪除訊息範本');
        } catch (error) {
            toast.error('刪除範本失敗');
        }
    };

    const handleEditTemplate = async (index) => {
        if (editingTemplate?.index === index) {
            try {
                const newTemplates = [...templates];
                newTemplates[index] = editingTemplate.text;
                await updateTemplatesInFirebase(newTemplates);
                setTemplates(newTemplates);
                setEditingTemplate(null);
                toast.success('已更新訊息範本');
            } catch (error) {
                toast.error('更新範本失敗');
            }
        } else {
            setEditingTemplate({
                index,
                text: templates[index]
            });
        }
    };

    const handleTemplateClick = (template) => {
        onSelectTemplate(template);
        onClose();
    };

    return (
        <Modal
            closeModal={onClose}
            isOpen={isOpen}
            title="訊息範本管理"
            width="max-w-2xl"
        >
            <div className="flex-colo gap-6">
                {/* Add new template section */}
                <div className="flex gap-2 w-full">
                    <input
                        type="text"
                        value={newTemplate}
                        onChange={(e) => setNewTemplate(e.target.value)}
                        placeholder="輸入新的訊息範本"
                        className="flex-1 p-3 border border-gray-300 rounded-lg"
                    />
                    <button
                        onClick={handleAddTemplate}
                        disabled={isLoading || !newTemplate.trim()}
                        className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:bg-gray-300"
                    >
                        新增範本
                    </button>
                </div>

                {/* Templates list */}
                <div className="w-full space-y-3">
                    {templates.map((template, index) => (
                        <div
                            key={index}
                            className="flex items-center gap-2 p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                        >
                            {editingTemplate?.index === index ? (
                                <input
                                    type="text"
                                    value={editingTemplate.text}
                                    onChange={(e) => setEditingTemplate({ index, text: e.target.value })}
                                    className="flex-1 p-2 border border-gray-300 rounded"
                                />
                            ) : (
                                <button
                                    onClick={() => handleTemplateClick(template)}
                                    className="flex-1 text-left hover:text-blue-600"
                                >
                                    {template}
                                </button>
                            )}
                            <button
                                onClick={() => handleEditTemplate(index)}
                                className="p-2 text-blue-500 hover:text-blue-600"
                            >
                                <FiEdit size={18} />
                            </button>
                            <button
                                onClick={() => handleDeleteTemplate(index)}
                                className="p-2 text-red-500 hover:text-red-600"
                            >
                                <RiDeleteBin6Line size={18} />
                            </button>
                        </div>
                    ))}
                    {templates.length === 0 && (
                        <p className="text-center text-gray-500 py-4">尚未建立任何訊息範本</p>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default MessageTemplateModal;